import React from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import * as callApiUtil from '../../utils/callApiUtil';
import * as validation from '../../utils/validation';
import publicIp from 'public-ip';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      captcha: '',
      // 顯示頁面
      step: '1',
      isLoading: false,
      isValidate: true,
      // 顯示聯絡我們
      contactus: false,
      form: {
        userId: { value: '', text: '' },
        loginId: { value: '', text: '' },
        password: { value: '', text: '' },
        validate: { value: '', text: '' },
      },
      menuList: {},
      userIdErrmsg: '',
      accountIdErrmsg: '',
      passwordErrmsg: '',
      infoErrmsg: '',
      captchaErrmsg: '',
      timer: '',
    };
  }

  //產生驗證碼
  componentDidMount = () => {
    this.setState({ isLoading: true });
    let GenCaptchaVin = {};
    callApiUtil.callApi('/ECPAP/API/CaptchaController/genCaptcha.action', GenCaptchaVin).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        this.setState({ captcha: 'data:image/jpg;base64,' + response.captchaImage });
      }
      let timer = setInterval(this.refreshCaptcha, 600000);
      this.setState({ timer: timer, isLoading: false });
    });
  };

  refreshCaptcha = () => {
    let GenCaptchaVin = {};
    callApiUtil.callApi('/ECPAP/API/CaptchaController/genCaptcha.action', GenCaptchaVin).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        this.setState({ captcha: 'data:image/jpg;base64,' + response.captchaImage });
      } else {
        //API回傳失敗 不知道要回傳什麼
      }
    });
  };

  //每個欄位在onChange時把value放入form裡面
  onChange = (n, v) => {
    let form = Object.assign(this.state.form);
    form[n].value = v;
    this.setState({
      form: form,
    });
    this.forceUpdate();
    //資料到後端登入失敗後，必須修改前端欄位才能再按登入
    this.setState({ isValidate: true });
  };

  onSubmit = () => {
    //清空後端errorMsg
    this.setState({ infoErrmsg: '' });
    //驗證資料
    let isValid = true;
    let userIdErrmsg = '';
    let accountIdErrmsg = '';
    let passwordErrmsg = '';
    let captchaErrmsg = '';

    //檢查所有欄位
    if (validation.isEmpty(this.state.form.userId.value)) {
      isValid = false;
      userIdErrmsg = '統編不得為空';
    }
    if (validation.isEmpty(this.state.form.loginId.value)) {
      isValid = false;
      accountIdErrmsg = '帳號不得為空';
    }
    if (validation.isEmpty(this.state.form.password.value)) {
      isValid = false;
      passwordErrmsg = '密碼不得為空';
    }
    if (validation.isEmpty(this.state.form.validate.value)) {
      isValid = false;
      captchaErrmsg = '驗證碼不得為空';
    }
    this.setState(() => ({
      userIdErrmsg: userIdErrmsg,
      accountIdErrmsg: accountIdErrmsg,
      passwordErrmsg: passwordErrmsg,
      captchaErrmsg: captchaErrmsg,
    }));

    if (isValid) {
      this.setState({ isLoading: true });
      // 改從後端取得IP

      //組成API input Value
      let LoginPageVin = {
        userID: this.state.form.userId.value,
        loginID: this.state.form.loginId.value,
        // 0330 弱點掃描，將密碼名稱改為 credential
        credential: this.state.form.password.value,
        validateCode: this.state.form.validate.value,
      };

      //call login API
      callApiUtil.callApi('/ECPAP/API/LoginPageController/loginPage.action', LoginPageVin).then((response) => {
        if (response.resultMessage.code === 's' && !response.needToChangePWD) {
          response.systemMenuAuthList.pop(); //將最後一筆移除(首頁)
          //API回傳成功
          //1.將munu清單與sidbar清單放入 session
          let menuList = response.systemMenuAuthList.map((data) => {
            let uri = '';
            let pageList = [];
            if (data.pageAuthList.length > 0) {
              //將第一筆放入menu 的uri，若沒有siderBar 則直接從menu點選此路徑
              uri = data.pageAuthList[0].uri;
              //siderBar過濾條件: showLink =true 加入siderBar
              pageList = data.pageAuthList.filter((value) => value.showLink == true);
            }
            return {
              menuId: data.moduleId,
              menuName: data.moduleName,
              uri: uri,
              pageList: pageList,
            };
          });
          let menuobject = { menuList: menuList };
          menuobject = JSON.stringify(menuobject);
          window.localStorage.setItem('menuList', menuobject);
          window.localStorage.setItem('accountName', response.accountName);
          clearInterval(this.state.timer);
          this.props.history.push('/index');
        } else if (response.resultMessage.code === 's' && response.needToChangePWD) {
          //需要重新設定密碼
          this.props.history.push({
            pathname: '/forceChangePwd',
          });
        } else if (response.resultMessage.code == 'MF_002_001' || response.resultMessage.code == 'MF_002_002') {
          //輸入欄位資訊驗證錯誤or登入資訊錯誤
          this.setState({ infoErrmsg: '登入資訊有誤' });
          this.setState({ isLoading: false });
          this.setState({ isValidate: false });
          this.componentDidMount();
        } else if (response.resultMessage.code == 'MF_002_006') {
          //輸入驗證碼錯誤
          this.setState({ captchaErrmsg: '請輸入正確的驗證碼' });
          this.setState({ isLoading: false });
          this.componentDidMount();
        } else {
          //API回傳失敗
          this.setState({ isLoading: false });
          this.componentDidMount();
        }
      });
    }
  };

  onSelect = (value) => {
    this.setState(() => ({ step: value }));
  };

  render() {
    return (
      <React.Fragment>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <Helmet>
          <title>FETnet 遠傳</title>
        </Helmet>
        <div className='bg-img pt-3'>
          <div className='container pb-5 pt-3'>
            {/* 登入資訊 */}
            <Formsy className='pl-2 pr-2 pb-1 pt-4 bg-white shadow'>
              <div className='row'>
                {/* 左側登入 */}
                <div className='col-4 ml-3 mr-3 mt-0 mb-5 pl-3'>
                  <h3 className='font-weight-bold ml-2 text-black mb-2'>登入</h3>

                  <LabelInput
                    className='ml-1 mb-2 mt-2'
                    name='userId'
                    value={this.state.form.userId.value}
                    required={true}
                    // label='統一編號'
                    placeholder='請輸入統一編號或其他編號'
                    onChange={this.onChange}
                    failResultMsg={this.state.userIdErrmsg}
                  />
                  <LabelInput
                    className='ml-1 mb-2 mt-2'
                    name='loginId'
                    value={this.state.form.loginId.value}
                    required={true}
                    // label='帳號'
                    placeholder='請輸入帳號'
                    onChange={this.onChange}
                    failResultMsg={this.state.accountIdErrmsg}
                  />
                  <LabelInput
                    type='password'
                    className='ml-1 mb-2 mt-2'
                    name='password'
                    value={this.state.form.password.value}
                    required={true}
                    // label='密碼'
                    placeholder='請輸入密碼'
                    infoErrmsg
                    onChange={this.onChange}
                    failResultMsg={this.state.passwordErrmsg}
                  />
                  <div className='no-border mt-2'>
                    <img src={this.state.captcha} className='pl-2' alt='validate'></img>
                    <span className='pl-2 small '>
                      更換驗證碼
                      <img
                        src={process.env.PUBLIC_URL + '/resources/common/images/icon_reload.png'}
                        className='ml-1 pointer'
                        onClick={() => this.refreshCaptcha()}
                        alt='refresh'></img>
                    </span>
                  </div>
                  <LabelInput
                    className='ml-1 mt-3 mb-2'
                    name='validate'
                    value={this.state.form.validate.value}
                    required={true}
                    // label='密碼'
                    placeholder='請輸入上方驗證碼'
                    onChange={this.onChange}
                    failResultMsg={this.state.infoErrmsg == '' ? this.state.captchaErrmsg : this.state.infoErrmsg}
                  />
                  <div className='ml-2 mt-3'>
                    <button
                      className='is-primary fui-button is-large align-middle'
                      onClick={() => this.onSubmit()}
                      disabled={!this.state.isValidate}>
                      繼續
                    </button>
                    <span className='pointer align-middle' onClick={() => this.props.history.push('/passwordReset')}>
                      <u>忘記密碼</u>
                    </span>
                  </div>
                  <div className='mt-3 ml-2 font-weight-bold'>
                    還不是會員嗎？
                    <a className='red pointer' onClick={() => this.props.history.push('/applyAccountPage')}>
                      <u>立即註冊</u>
                    </a>
                    遠傳企業用戶會員 ！
                  </div>
                  <div className='mt-3 ml-2 font-weight-bold h5'>
                    <a
                      className='title-blue pointer'
                      href='#'
                      onClick={() => window.open('https://login2.fetnet.net/k8slogin/eaiapp/loginForm', '_blank')}>
                      <u>企業門號自助服務</u>
                    </a>
                  </div>
                </div>
                {/* 登入右側 */}
                <div className='w-60 mt-1 ml-1'>
                  {/* 登入右側，上方row */}
                  <div className='row news'>
                    {/* <div className='col-5'> */}
                    <div className='col-7 mr-1'>
                      <tbody className='mt-2 ml-1'>
                        <div className='title-blue is-text-bold h4 mb-4'>
                          歡迎您進入
                          <br />
                          「企業客戶網路服務平台-ECP」
                        </div>
                        <div className='h6 mb-3 font-weight-bold'>
                          這是一個提供企業客戶自助操作平台，內容提供您
                          <span className='title-blue'>電信相關解決方案</span>，讓您的工作變得更便捷，對地球更環保。
                        </div>
                        <div className='h6 mb-3 font-weight-bold'>
                          心動嗎？簡單＂二＂步驟，審核通過後，立即使用！
                          <br />
                          還不是會員嗎？
                          <a className='red pointer' onClick={() => this.props.history.push('/applyAccountPage')}>
                            <u>立即註冊</u>
                          </a>
                          遠傳企業用戶會員 ！
                        </div>
                        <div className='h6 font-weight-bold'>
                          <span className='text-italic title-blue'>New&nbsp;</span>
                          <a
                            className='red pointer'
                            href='#'
                            onClick={() => window.open('https://ecp.fareastone.com.tw/prototype/index.html', '_blank')}>
                            <u>立即體驗</u>
                          </a>
                        </div>
                        <div className='h6 mt-3 mb-4 font-weight-bold'>
                          <a
                            className='red pointer'
                            href={process.env.PUBLIC_URL + '/resources/common/pdf/電費調漲通知函.pdf'}
                            target='_blank'
                            rel='noreferrer'>
                            IDC&nbsp;機房電費調整通知
                          </a>
                        </div>
                        <br />
                        <div className='h6 font-weight-bold mt-3'>
                          <div className='brown'>&nbsp;&nbsp;重要公告</div>
                          <br />
                          <iframe
                            className='no-border'
                            src='https://service.seed.net.tw/register-cgi/service_notice?FUNC=ser_notice_qryE&Category=00&Start=1'
                            height='200'
                            width='100%'
                            title='重要公告'></iframe>
                        </div>
                      </tbody>
                    </div>
                    <div className='col-4 ml-5'>
                      <tbody className='services ml-2 col-4 pt-3 pb-1'>
                        <img src={process.env.PUBLIC_URL + '/resources/common/images/ICT_Repair.png'} 
                        onClick={() => window.open('https://fetscs.fareastone.com.tw:31101/ems_frontend/ict/home')}></img>
                        <tr>
                          <td
                            className='h6 font-weight-bold pointer'
                            onClick={() => window.open('https://fetscs.fareastone.com.tw:31101/ems_frontend/ict/home')}>
                            ICT專案線上報修
                          </td>
                        </tr>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div className='title-blue is-text-bold h4 mb-1'>常用服務</div>
                        <tr>
                          <td
                            className='h6 font-weight-bold pointer'
                            onClick={() => window.open(process.env.PUBLIC_URL + '/commonServicePage')}>
                            各式表單下載
                          </td>
                        </tr>
                        <tr>
                          <td
                            className='h6 font-weight-bold pointer'
                            onClick={() => window.open(process.env.PUBLIC_URL + '/areaServerPage')}>
                            各地區服務主機
                          </td>
                        </tr>
                        <tr>
                          <td
                            className='h6 font-weight-bold pointer'
                            onClick={() =>
                              window.open(
                                'https://service.seed.net.tw/home/speed/speed.htm',
                                '_blank',
                                'height=800, width=800, resizable=0'
                              )
                            }>
                            線上測速
                          </td>
                        </tr>
                        <tr>
                          <td
                            className='h6 font-weight-bold pointer'
                            onClick={() => window.open(process.env.PUBLIC_URL + '/productManualPage')}>
                            產品使用手冊下載
                          </td>
                        </tr>
                        <tr>
                          <td
                            className='h6 font-weight-bold pointer'
                            onClick={() => window.open(process.env.PUBLIC_URL + '/cloudServicePage')}>
                            產品平台常用連結
                          </td>
                        </tr>
                        <tr>
                          <td
                            className='h6 font-weight-bold pointer'
                            onClick={() =>
                              window.open(
                                'https://ecp.fareastone.com.tw/MRTG/login',
                                '_blank',
                                'height=800, width=800, resizable=0'
                              )
                            }>
                            流量查詢 (10帳號)
                          </td>
                        </tr>
                        <tr>
                          <td
                            className='h6 font-weight-bold pointer'
                            onClick={() =>
                              window.open(
                                'https://ecp.fareastone.com.tw/mrtg-frontend/',
                                '_blank',
                                'height=800, width=800, resizable=0'
                              )
                            }>
                            APT用戶流量查詢
                          </td>
                        </tr>
                        <tr>
                          <td
                            className='h6 font-weight-bold pointer'
                            onClick={() => window.open(process.env.PUBLIC_URL + '/eInvoiceServicePage')}>
                            企業用戶免登入查發票
                          </td>
                        </tr>
                        <td
                          className='font-default contactus pt-2 text-right font-weight-bold pointer'
                          onClick={() => window.open(process.env.PUBLIC_URL + '/commonServicePage')}>
                          MORE
                        </td>
                      </tbody>
                    </div>
                  </div>
                </div>
              </div>
            </Formsy>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(LoginPage);
